"use client";

import React, { useEffect, useRef, useState } from "react";
import rating from "../../assets/flightsHome/ratingHead.png";
import Image from "next/image";
import Link from "next/link";
import { IoIosSearch } from "react-icons/io";
import profileImg from "../../../public/profile.png";
import globalAxiosURL from "@/hooks/globalAxiosURL";
import ReactStars from "react-rating-stars-component";
import { TiStarFullOutline, TiStarOutline } from "react-icons/ti";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const FlightRating = () => {
  const [loading, setLoading] = useState(true);
  const axios = globalAxiosURL();
  const [reviews, setReviews] = useState({});
  const [airlines, setAirlines] = useState([]);
  const [airlinesLoading, setAirlinesLoading] = useState(true);
  const [airlineList, setAirlineList] = useState([]);
  const [filteredAirlinesList, setFilteredAirlinesList] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  // console.log(reviews);
  // Manage outside clicks
  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  };

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    setSearchText(inputText);

    // if (inputText.length > 0) {
    //   setIsDropdownOpen(true);
    // } else {
    //   setIsDropdownOpen(false);
    // }

    // to filter the dropdown list
    const filterList = airlineList.filter((airline) =>
      airline.toLowerCase().startsWith(inputText.toLowerCase())
    );
    setFilteredAirlinesList(filterList.length < 1 ? airlineList : filterList);
    setIsDropdownOpen(true);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchAirlineRating = async () => {
      try {
        const res = await axios.get("/airlines");
        const names = res.data.map((airlines) => airlines.airlinesName);
        // const photo = res.data.map((airlines) => airlines.airlinesName);
        setAirlines(res.data.sort(() => Math.random() - 0.5).slice(0, 3));
        setAirlineList(names);
        setAirlinesLoading(false);
      } catch (error) {
        console.log("Error:", error);
      }
    };

    fetchAirlineRating();
  }, [axios, setAirlines]);

  const fetchSpecificAirlineRating = async (airlineName) => {
    try {
      const res = await axios.get(`/review?&airlinesInfo=${airlineName}`);
      return res.data;
    } catch (error) {
      console.error("Error fetching review data:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchAllReviews = async () => {
      const reviewData = {};
      for (const airline of airlines) {
        const data = await fetchSpecificAirlineRating(airline.airlinesName);
        if (data) {
          reviewData[airline.airlinesName] = data;
        }
      }
      setReviews(reviewData);
      setLoading(false);
    };

    fetchAllReviews();
  }, [airlines]);

  return (
    <div
      data-aos="fade-up"
      className="bg-[#D9D9D9B3] rounded-lg pt-8 pb-8 md:pb-16 px-4 md:px-16 mb-16"
    >
      <div className="text-center">
        <Image className="mx-auto" alt="" src={rating} />
        <h2 className="font-bold mt-4 mb-3 text-lg md:text-xl">
          View traveler ratings
        </h2>
        <p className="w-90% mx-auto text-sm md:text-base">
          Read through passenger analyses to learn what they liked (or didn't
          like) about food, legroom, and customer service.
        </p>
        <Link
          href={"/pages/Airlines"}
          className="underline pt-2 inline-block font-normal underline-offset-2"
        >
          Browse all airlines
        </Link>
        <div className="relative w-[80%] mx-auto mt-4">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <IoIosSearch size={22} />
          </div>
          <input
            type="text"
            value={searchText}
            onChange={handleInputChange}
            autoComplete="off"
            onFocus={handleInputChange}
            id="simple-search"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-[10px] block w-full focus:ring-2 focus:ring-blue-500 outline-none ps-10 p-2.5"
            placeholder="Search Airlines"
            required
          />
          <div>
            {isDropdownOpen && (
              <ul
                ref={dropdownRef}
                className="absolute top-full w-full max-h-[300px] overflow-y-auto bg-white border shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] z-50 rounded-b-md mt-1"
              >
                {filteredAirlinesList.map((airline, idx, array) => (
                  <Link
                    key={idx}
                    href={`/pages/flights/airline-review/${airline}`}
                  >
                    <li
                      // onClick={() => handleLocationSelect(airline)}
                      // onClick={() => handleSetValue(airline)}
                      className={`px-3 py-4 ${
                        array.length - 1 === idx ? "" : "border-b"
                      }  hover:bg-gray-100 text-left cursor-pointer`}
                    >
                      {airline}
                    </li>
                  </Link>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
      <div className="flex lg:grid md:grid-cols-3 gap-4 mt-8 overflow-x-auto">
        {(loading || airlinesLoading) && (
          <>
            <div className="rounded-lg">
              <Skeleton count={1} height={200} />
            </div>
            <div className="rounded-lg hidden md:block">
              <Skeleton count={1} height={200} />
            </div>
            <div className="rounded-lg hidden md:block">
              <Skeleton count={1} height={200} />
            </div>
          </>
        )}
        {airlines?.map((r) => (
          <Link
            key={r.id}
            href={`/pages/flights/airline-review/${r.airlinesName}`}
          >
            {/* <div className="bg-white p-4 pb-8 rounded-lg min-w-[270px] md:w-full h-full">
              <h3 className="font-bold mb-3 md:text-lg lg:text-xl">
                {r.airlinesName}
              </h3>
              <div className=" lg:text-base md:text-sm text-xs flex items-center gap-2">

                <ReactStars
                  edit={false}
                  count={5}
                  value={r?.airlinesRating}
                  color="#C0C0C0"
                  size={25}
                  isHalf={true}
                  emptyIcon={<TiStarOutline />}
                  fullIcon={<TiStarFullOutline />}
                  activeColor="#00703E"
                />
                <p className="text-sm">{reviews[r.airlinesName]?.length}</p>
              </div>
              <div className="flex gap-2 mt-3 flex-row">
                <div className="size-9 md:size-10 rounded-full min-w-9 md:min-w-10 overflow-hidden">
                  <Image
                    src={
                      reviews[r.airlinesName] &&
                      reviews[r.airlinesName][0]?.userInfo?.profileImage
                        ? reviews[r.airlinesName][0]?.userInfo?.profileImage
                        : profileImg
                    }
                    alt="profile-image"
                    width={100}
                    height={100}
                    className="w"
                  ></Image>
                </div>
                <p className="text-sm ">
                  {reviews[r.airlinesName] ? (
                    reviews[r.airlinesName][0].description.slice(0, 200)
                  ) : (
                    <Skeleton></Skeleton>
                  )}
                </p>
              </div>
            </div> */}
            <div className="aspect-auto p-6 border min-w-[270px] md:w-full rounded-3xl bg-white h-full">
              <h3 className="font-bold mb-3 md:text-lg lg:text-xl">
                {r.airlinesName}
              </h3>
              <div className=" lg:text-base md:text-sm text-xs flex items-center gap-2">
                <ReactStars
                  edit={false}
                  count={5}
                  value={r?.airlinesRating}
                  color="#C0C0C0"
                  size={25}
                  isHalf={true}
                  emptyIcon={<TiStarOutline />}
                  fullIcon={<TiStarFullOutline />}
                  activeColor="#00703E"
                />
                <p className="text-sm">{reviews[r.airlinesName]?.length}</p>
              </div>
              <div className="flex gap-2 items-center mt-3">
                <div className="size-9 md:size-10 rounded-full min-w-9 md:min-w-10 overflow-hidden">
                  <Image
                    src={
                      reviews[r.airlinesName] &&
                      reviews[r.airlinesName][0]?.userInfo?.profileImage
                        ? reviews[r.airlinesName][0]?.userInfo?.profileImage
                        : profileImg
                    }
                    alt="profile-image"
                    width={100}
                    height={100}
                    className="w"
                  ></Image>
                </div>
                <div>
                  <h6 className="text-base font-semibold text-gray-700">
                    {/* {reviews[r.airlinesName][0]?.userInfo} */}
                    {reviews[r.airlinesName] ? (
                      reviews[r.airlinesName][0]?.userInfo?.name
                    ) : (
                      <Skeleton></Skeleton>
                    )}
                  </h6>
                </div>
              </div>
              <p className="mt-4 text-sm">
                {reviews[r.airlinesName] ? (
                  reviews[r.airlinesName][0].description.slice(0, 200)
                ) : (
                  <Skeleton></Skeleton>
                )}{" "}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default FlightRating;
